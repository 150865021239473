import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class ProjectsCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      projects: ''
    };
  }

  componentDidMount() {
    this.getProjects(this.state.user.id);
  }

  getProjects(id) {
    fetch(`/users/projects/${id}`, {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(result => {
        let str = '';
        result.forEach(res => {
          str += res.project_name;
          str += ' - ';
        });
        return str.slice(0, str.length - 2);
      })
      .then(str => {
        this.setState({ projects: str });
      })
      .catch(err => console.log('Error caught : ', err));
  }

  render() {
    let userData = this.state.user;
    return (
      <Link
        to={{
          pathname: `userprojects/${this.state.user.id}`,
          state: { userData: userData }
        }}
        className="user-link"
      >
        {this.state.projects || '+'}
      </Link>
    );
  }
}

export default ProjectsCell;
