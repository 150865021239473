import React, { Component } from 'react';
import TimesheetsAdmin from './TimesheetsAdmin/TimesheetsAdmin';
import './UserPanel.scss';

export class UserPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      selectedDate: new Date()
    };
  }

  update(id) {
    fetch(`/users/user/${id}`, {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(result => {
        this.setState({ userData: result });
      })
      .catch(err => console.log('Error caught here : ', err));
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.update(id);
  }

  handleChange(date) {
    const { id } = this.props.match.params;
    this.setState({
      selectedDate: date
    });
    this.update(id);
  }

  render() {
    const { id } = this.props.match.params;
    const user = this.state.userData;
    if (user.timesheets) {
      return (
        <div>
          <h2 className="admin-title">
            User {user.first_name} {user.last_name}
          </h2>
          <TimesheetsAdmin
            updateParent={() => this.update(id)}
            id={id}
            user={user}
          />
        </div>
      );
    } else {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    }
  }
}

export default UserPanel;
