import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import keystone from '../../../assets/img/keystone.png';
import pdf from '../../../assets/Keytime_UserGuide.pdf';
import './Navbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faQuestion } from '@fortawesome/free-solid-svg-icons';
const jwt_decode = require('jwt-decode');

class Navbar2 extends Component {
  logOut(e) {
    e.preventDefault();
    localStorage.removeItem('usertoken');
    this.props.history.push('/');
  }

  render() {
    let first_name, role;
    if (localStorage.usertoken) {
      const token = localStorage.usertoken;
      const decoded = jwt_decode(token);
      first_name = decoded.first_name;
      role = decoded.role;
    }
    let loginRegLink = (
      <Nav className="mr-auto">
        <Nav.Link as={Link} to="/login" className="nav-link2" eventKey="1">
          Login
        </Nav.Link>
        <Nav.Link as={Link} to="/register" className="nav-link2" eventKey="2">
          Register
        </Nav.Link>
      </Nav>
    );
    let adminLink = (
      <Nav className="mr-auto">
        <Nav.Link as={Link} to="/profile" className="nav-link2" eventKey="1">
          {first_name}
        </Nav.Link>{' '}
        <Nav.Link as={Link} to="/users" className="nav-link2" eventKey="2">
          Users Management
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/usertimesheets"
          className="nav-link2"
          eventKey="3"
        >
          Timesheets Management
        </Nav.Link>{' '}
        <Nav.Link
          as={Link}
          to="/projectsmanagement"
          className="nav-link2"
          eventKey="4"
        >
          Projects Management
        </Nav.Link>
      </Nav>
    );

    let userLink = (
      <Nav className="mr-auto">
        <Nav.Link as={Link} to="/profile" className="nav-link2" eventKey="1">
          {first_name}
        </Nav.Link>{' '}
        <Nav.Link as={Link} to="/timesheets" className="nav-link2" eventKey="2">
          Timesheets
        </Nav.Link>
      </Nav>
    );

    let logoutLink = (
      <Nav className="ml-auto">
       <Nav.Link
          className="nav-link2"
          href={pdf}
          target='blank'
          eventKey="6"
        >
          <FontAwesomeIcon icon={faQuestion} />
        </Nav.Link>
        <Nav.Link
          className="nav-link2"
          as={Link}
          to="/"
          onClick={this.logOut.bind(this)}
          eventKey="7"
        >
          <FontAwesomeIcon icon={faSignOutAlt} />
        </Nav.Link>
      </Nav>
    );

    return (
      <Navbar collapseOnSelect fixed="top" expand="lg" bg="dark" variant="dark">
        <Navbar.Brand>
          <Nav.Link as={Link} to="/" eventKey="1">
            <img className="keystone-logo" src={keystone} alt="Keystone Logo" />
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {role === 'admin'
            ? adminLink
            : role === 'user'
            ? userLink
            : loginRegLink}
          {role === 'admin' || role === 'user' ? logoutLink : null}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withRouter(Navbar2);
