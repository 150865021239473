import React, { Component } from 'react';
import Userstable from './Userstable/Userstable';
import './Usersmanagement.scss';

class Usersmanagement extends Component {
  constructor() {
    super();
    this.state = {
      users: []
    };
  }

  componentDidMount() {
    fetch('/users/list', {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(result => {
        if (result.error) {
          console.log('Expired token', result.error);
          localStorage.removeItem('token');
          this.props.history.push('/login');
        } else {
          this.setState({ users: result });
        }
      })
      .catch(err => {
        console.log('Error happenend' + err);
      });
  }

  render() {
    const users = this.state.users;
    return (
      <div>
        <h1 className="list-title">Users Management</h1>
        <Userstable users={users} />
      </div>
    );
  }
}

export default Usersmanagement;
