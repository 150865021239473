import React, { Component } from 'react';
import './TimesheetAdmin.scss';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withSnackbar } from 'notistack';
import Checkbox from '@material-ui/core/Checkbox';
import changeLockStatus from '../../../../../../actions/changeLockStatus';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
});

export class TimesheetAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: this.props.user,
      first_name: this.props.first_name,
      last_name: this.props.last_name,
      totalWorked: 0,
      previousNum: 0,
      daysWorked: {},
      timesheetsList: this.props.timesheets,
      lockedStatus: this.props.timesheets[this.props.sheetIndex].locked,
      projectsArray: []
    };
  }

  componentDidMount() {
    this.totalWorkedMath();
    if (this.props.timesheets) {
      let temp = [];
      this.props.timesheets.forEach(sheet => {
        if (!temp.includes(sheet.project)) {
          temp.push(sheet.project);
        }
      });
      fetch('/users/projects/getbyids', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ projArr: temp }),
        headers: new Headers({
          Authorization: localStorage.usertoken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      })
        .then(res => res.json())
        .then(projectsArray => {
          this.setState({ projectsArray: projectsArray });
        })
        .catch(err => console.log('Error during projects retrieval', err));
    }
  }

  totalWorkedMath = () => {
    let workedDays;
    let tot = 0;
    let copy = this.state.timesheetsList;
    if (this.state.timesheetsList[this.props.sheetIndex] !== undefined) {
      workedDays = this.state.timesheetsList[this.props.sheetIndex].worked_days;

      let entries = Object.entries(workedDays);
      for (let [day, hours] of entries) {
        if (day) {
          tot += hours;
        }
      }
    }
    if (copy[this.props.sheetIndex] !== undefined) {
      copy[this.props.sheetIndex].month_total = tot;
      this.setState({ timesheetsList: copy, totalWorked: tot });
    }
  };

  getWeekends(now) {
    let arrayOfWeekends = [];
    function getDaysInMonth(now) {
      return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    }
    let days = getDaysInMonth(now);

    for (let i = 1; i <= days; i++) {
      let newDate = new Date(now.getFullYear(), now.getMonth(), i);
      if (newDate.getDay() === 0 || newDate.getDay() === 6) {
        arrayOfWeekends.push(i);
      }
    }

    return arrayOfWeekends;
  }

  handleCheckbox(e, user) {
    let indexOfTimesheetToUpdate = this.props.sheetIndex;
    changeLockStatus(
      indexOfTimesheetToUpdate,
      user.id,
      !this.state.lockedStatus
    ).then(() => this.props.updateParent());
    this.setState({ lockedStatus: !this.state.lockedStatus }, () => {
      console.log(
        'Locked status from state, after change',
        this.state.lockedStatus
      );
    });
  }

  render() {
    const { classes, selectedDate, sheetIndex } = this.props;
    let user = this.state.userData;
    let arrayOfWeekends = this.getWeekends(selectedDate);
    let els = [];
    let now = selectedDate;
    let projectId = this.state.timesheetsList[sheetIndex].project;
    let currentProject = this.state.projectsArray.filter(project => {
      return project.project_id === projectId;
    });
    function getDaysInMonth(now) {
      return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    }
    const days = getDaysInMonth(now);

    for (let i = 1; i <= days; i++) {
      els.push(i);
    }

    return (
      <div className="timesheet-container">
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Project</CustomTableCell>
                <CustomTableCell align="left">Day</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <CustomTableCell className="project-cell">
                  {currentProject[0]
                    ? currentProject[0].project_name
                    : 'Loading'}
                </CustomTableCell>
                <CustomTableCell align="left">
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow className="fullrow limit-height">
                        {els.map(day => {
                          return arrayOfWeekends.includes(day) ? (
                            <th className="header-days weekend" key={day}>
                              {day}
                            </th>
                          ) : (
                            <th className="header-days" key={day}>
                              {day}
                            </th>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className="fullrow limit-height">
                        {els.map(day => {
                          let dayKey = `day${day}`;
                          return (
                            <td className="daycell" key={day}>
                              <input
                                id={`day${day}`}
                                type="text"
                                disabled
                                placeholder={
                                  this.state.timesheetsList[
                                    this.props.sheetIndex
                                  ] !== undefined
                                    ? this.state.timesheetsList[sheetIndex]
                                        .worked_days[dayKey]
                                    : 0
                                }
                                key={day}
                              />
                            </td>
                          );
                        })}
                      </TableRow>
                    </TableBody>
                  </Table>
                </CustomTableCell>
              </TableRow>
            </TableBody>
          </Table>

          <div className="totaldays">
            <div className="total-text">
              Total : {this.state.totalWorked}/
              {(days - arrayOfWeekends.length) * 8} hours
            </div>

            <div className="save-timesheet">
              <Checkbox
                onChange={e => this.handleCheckbox(e, user)}
                checked={this.state.lockedStatus}
                value={`user${user.id}`}
                disabled={!this.props.timesheets[sheetIndex].require_lock}
              />
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(TimesheetAdmin));
