import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Usersmanagement from './components/Administration/Usermanagement/Usersmanagement';
import ProjectsManagement from './components/Administration/ProjectsManagement/ProjectsManagement';
import Timesheets from './components/Users/Timesheets/Timesheets';
import Login from './components/common/Auth/Login';
import Register from './components/common/Auth/Register';
import Profile from './components/common/Profile/Profile';
import UserPanel from './components/Administration/TimesheetsManagement/UserPanel/UserPanel';
import Landing from './components/common/Landing/Landing';
import Navbar from './components/common/Navbar/Navbar';
import Error404 from './components/common/Error404/Error404';
import Administration from './components/Administration/TimesheetsManagement/Administration';
import Loader from './components/common/Loader/Loader';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { auth } from './actions/UserFunctions';
import Userprojects from './components/Administration/Usermanagement/Userstable/Userprojects/Userprojects';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const ProtectedAdminRoute = ({
      component: Component,
      isAdmin,
      ...rest
    }) => (
      <Route
        {...rest}
        render={props =>
          auth() === 'admin' ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
      />
    );
    const ProtectedRoute = ({ component: Component, isLogged, ...rest }) => (
      <Route
        {...rest}
        render={props =>
          auth() === 'user' || auth() === 'admin' ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
      />
    );

    if (loading) {
      return <Loader />;
    } else {
      return (
        <div className="App">
          <Navbar />

          <div className="container">
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <ProtectedRoute exact path="/profile" component={Profile} />
              <ProtectedRoute exact path="/timesheets" component={Timesheets} />
              <ProtectedAdminRoute
                exact
                path="/users"
                component={Usersmanagement}
              />
              <ProtectedAdminRoute
                exact
                path="/projectsmanagement"
                component={ProjectsManagement}
              />
              <ProtectedAdminRoute
                exact
                path="/userprojects/:id"
                component={Userprojects}
              />
              <ProtectedAdminRoute
                exact
                path="/userpanel/:id"
                component={UserPanel}
              />
              <ProtectedAdminRoute
                exact
                path="/usertimesheets"
                component={Administration}
              />
              <Route exact path="/*" component={Error404} />
            </Switch>
          </div>
        </div>
      );
    }
  }
}

export default App;
