import React, { Component } from 'react';
import './ProjectsTable.scss';

export class ProjectsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: this.props.user_id,
      uproj: this.props.userProjects,
      aproj: this.props.allProjects
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userProjects !== this.props.userProjects) {
      this.setState({ uproj: nextProps.userProjects });
    }
    if (nextProps.allProjects !== this.props.allProjects) {
      this.setState({ aproj: nextProps.allProjects });
    }
  }

  diff(array, smallarray) {
    let diff = array.filter(obj => {
      return !smallarray.some(obj2 => {
        return obj.project_id === obj2.project_id;
      });
    });
    return diff;
  }

  handleAdd(projectid) {
    fetch(`/users/addproject/${this.props.user_id}/${projectid}`, {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(() => {
        this.props.refreshData();
      })
      .catch(err => console.log('Error while adding project to user', err));
  }
  handleRemove(projectid) {
    fetch(`/users/removeproject/${this.props.user_id}/${projectid}`, {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(() => {
        this.props.refreshData();
      })
      .catch(err => console.log('Error while adding project to user', err));
    this.props.refreshData();
  }

  render() {
    let uproj = this.state.uproj;
    let aproj = this.state.aproj;
    let diffResult = this.diff(aproj, uproj);
    return (
      <div className="proj-comp-container">
        <div className="projects-table-container">
          <div className="table-container1">
            <table>
              <tbody>
                <tr>
                  <th>Projects assigned to user</th>
                </tr>

                {uproj.map(proj => {
                  return (
                    <tr
                      key={proj.project_id}
                      onClick={() => this.handleRemove(proj.project_id)}
                    >
                      <td>{proj.project_name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="table-container2">
            <table>
              <tbody>
                <tr>
                  <th>All projects</th>
                </tr>
                {diffResult.map(proj => {
                  return (
                    <tr
                      key={proj.project_id}
                      onClick={() => this.handleAdd(proj.project_id)}
                    >
                      <td>{proj.project_name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectsTable;
