import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import changeLockStatus from '../../../../actions/changeLockStatus';
import './UsersRecap.scss';
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const styles = () => ({
  root: {
    width: '90%',
    margin: '50px auto',
    overflowX: 'auto'
  },
  table: {
    minWidth: '500px'
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#cccdce'
    }
  }
});

class UsersRecap extends Component {
  constructor(props) {
    super(props);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.state = {
      users: this.props.users
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.users !== this.props.users) {
      this.setState({ users: this.props.users });
    }
  }

  checkValidation(timesheets, month, year) {
    let validated = true;
    let res = timesheets.filter(sheet => {
      return sheet.month === month && sheet.year === year;
    });
    res.forEach(sheet => {
      if (sheet.locked === false) {
        validated = false;
      }
    });
    return validated;
  }

  handleCheckbox(e, user) {
    let lockedStatus;
    let currentMonth = this.props.date.getMonth() + 1;
    let indexOfTimesheetToUpdate = user.timesheets.findIndex(timesheet => {
      return timesheet.month === currentMonth;
    });
    lockedStatus = user.timesheets[indexOfTimesheetToUpdate].locked;
    changeLockStatus(indexOfTimesheetToUpdate, user.id, !lockedStatus).then(
      () => this.props.updateParent()
    );
  }

  render() {
    const { classes, date } = this.props;
    let users = this.state.users;
    let currentMonth = date.getMonth() + 1;
    let currentYear = date.getFullYear();
    let returnTotal = function(user) {
      let total = 0;
      user.timesheets
        .filter(timesheet => {
          return (
            timesheet.month === currentMonth && timesheet.year === currentYear
          );
        })
        .forEach((sheet, index, array) => {
          total += sheet.month_total;
        });
      return total;
    };
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.row}>
              <CustomTableCell key="0">ID</CustomTableCell>
              <CustomTableCell key="1">Name</CustomTableCell>

              <CustomTableCell key="2" align="center">
                Total for {currentMonth}/{currentYear}
              </CustomTableCell>
              <CustomTableCell key="3" align="center">
                Validation required
              </CustomTableCell>
              <CustomTableCell key="4" align="center">
                Validated
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user =>
              user.role !== 'admin' ? (
                <React.Fragment key={user.id}>
                  <TableRow className={classes.row} key={user.id}>
                    <CustomTableCell align="left">{user.id}</CustomTableCell>
                    <CustomTableCell align="left">
                      <Link to={`userpanel/${user.id}`} className="user-link">
                        {user.first_name + ' ' + user.last_name}
                      </Link>
                    </CustomTableCell>

                    <CustomTableCell align="center">
                      {user.timesheets.filter(timesheet => {
                        return (
                          timesheet.month === currentMonth &&
                          timesheet.year === currentYear
                        );
                      }) !== undefined
                        ? `${returnTotal(user)}`
                        : 0}{' '}
                      hours
                    </CustomTableCell>
                    <CustomTableCell align="center">No</CustomTableCell>
                    <CustomTableCell align="center">
                      <Checkbox
                        checked={this.checkValidation(
                          user.timesheets,
                          currentMonth,
                          currentYear
                        )}
                        disabled={true}
                      />
                    </CustomTableCell>
                  </TableRow>
                </React.Fragment>
              ) : null
            )}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

UsersRecap.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UsersRecap);
