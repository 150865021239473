import React, { Component } from 'react';
import TimesheetAdmin from './TimesheetAdmin/TimesheetAdmin';
import DatePicker from 'react-datepicker';
import './TimesheetsAdmin.scss';
import 'react-datepicker/dist/react-datepicker.css';

export class TimesheetsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      first_name: '',
      last_name: '',
      timesheets: [],
      user: this.props.user,
      selectedDate: new Date()
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    fetch(`/users/timesheets/${this.props.id}`, {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(result => {
        this.setState({ timesheets: result });
      })
      .catch(err => {
        alert(
          `An error happened when trying to retrieve the timesheets of the user with the following id: ${
            this.props.id
          }`
        );
        console.log(
          `Error : could not fetch the timesheets for the user with id : ${
            this.props.id
          }`,
          err
        );
      });

    fetch(`/users/projects/${this.props.id}`, {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(result => {
        return result;
      })
      .then(projects => {
        this.setState({ projects: projects });
      })
      .catch(err => console.log('Error caught : ', err));
  }

  handleChange(date) {
    this.setState({
      selectedDate: date
    });
  }

  render() {
    let selectedMonth = this.state.selectedDate.getMonth() + 1;
    let selectedYear = this.state.selectedDate.getFullYear();
    let timesheetsToRender = [];
    let timesheetsArray = [];
    if (this.state.timesheets[0] === undefined) {
      return null;
    } else {
      timesheetsArray = this.state.timesheets;
    }

    if (timesheetsArray.length >= 1) {
      timesheetsArray.forEach((timesheet, index) => {
        let d = new Date(timesheet.year, timesheet.month - 1);
        if (
          timesheet.month === selectedMonth &&
          timesheet.year === selectedYear
        ) {
          timesheetsToRender.push(
            <TimesheetAdmin
              updateParent={this.props.updateParent}
              user={this.state.user !== undefined ? this.state.user : []}
              projects={this.state.projects}
              timesheets={this.state.timesheets}
              key={index}
              selectedDate={d}
              sheetIndex={index}
              first_name={this.state.first_name}
              last_name={this.state.last_name}
            />
          );
        } else if (
          !timesheetsArray.find(
            timesheet =>
              timesheet.month === selectedMonth &&
              timesheet.year === selectedYear
          )
        ) {
          timesheetsToRender = [
            <div key={`${timesheet.month}/${timesheet.year}`}>
              <h2 className="mt-5">No timesheets for this period</h2>
            </div>
          ];
        }
      });
    }

    return (
      <div className="timesheets">
        <h3>
          Timesheet(s) for :
          <DatePicker
            id="custom-datepicker-keystone"
            selected={this.state.selectedDate}
            onChange={this.handleChange}
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
        </h3>
        {timesheetsToRender.map(timesheetRendered => timesheetRendered)}
      </div>
    );
  }
}

export default TimesheetsAdmin;
