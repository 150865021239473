import React, { Component } from 'react';
import UsersRecap from './UsersRecap/UsersRecap';
import DatePicker from 'react-datepicker';
import './Administration.scss';

export class Administration extends Component {
  constructor() {
    super();
    this.state = {
      usersData: [],
      selectedDate: new Date()
    };
    this.update = this.update.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  update() {
    fetch('users/alltimesheets', {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(result => {
        this.setState({ usersData: result });
      })
      .catch(err => {
        console.log('Expired token', err);
        localStorage.removeItem('token');
        this.props.history.push('/login');
      });
  }

  componentDidMount() {
    this.update();
  }

  handleChange(date) {
    this.setState({
      selectedDate: date
    });
    this.update();
  }

  render() {
    return (
      <div>
        <h1 className="admin-title">Timesheets Management</h1>
        <DatePicker
          id="custom-datepicker-admin"
          selected={this.state.selectedDate}
          onChange={this.handleChange}
          dateFormat="MM/yyyy"
          showMonthYearPicker
        />
        <UsersRecap
          users={this.state.usersData}
          updateParent={this.update}
          date={this.state.selectedDate}
        />
      </div>
    );
  }
}

export default Administration;
