import React, { Component } from 'react';
import ProjectsTable from './ProjectsTable/ProjectsTable';
import './Userprojects.scss';

export class Userprojects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: this.props.match.params.id,
      userProjects: [],
      allProjects: [],
      userData: this.props.location.state.userData
    };
  }
  componentDidMount() {
    this.refreshProjectsData(this.props.match.params.id);
  }

  refreshProjectsData() {
    fetch(`/users/projects/${this.props.match.params.id}`, {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(result => {
        return result;
      })
      .then(projects => {
        this.setState({ userProjects: projects });
      })
      .catch(err => console.log('Error caught : ', err));

    fetch('/users/allprojects', {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(result => {
        return result;
      })
      .then(projects => this.setState({ allProjects: projects }))
      .catch(err => console.log('Error caught : ', err));
  }

  handleRefresh = () => this.refreshProjectsData();

  render() {
    let userData = this.state.userData;
    return (
      <div>
        <h1 className="projects-title">{`${userData.first_name} ${
          userData.last_name
        }`}</h1>
        <ProjectsTable
          refreshData={this.handleRefresh}
          user_id={this.state.user_id}
          userProjects={this.state.userProjects}
          allProjects={this.state.allProjects}
        />
      </div>
    );
  }
}

export default Userprojects;
