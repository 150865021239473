import React, { Fragment, Component } from 'react';
import './ProjectsManagement.scss';
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';

export class ProjectsManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectNameInput: '',
      projectsFromFetch: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.getProjects();
  }

  getProjects() {
    fetch('/users/allprojects', {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(projects => this.setState({ projectsFromFetch: projects }))
      .catch(err => console.log('Error caught : ', err));
  }

  handleChange(e) {
    this.setState({ projectNameInput: e.target.value });
  }

  handleClick() {
    const action = key => (
      <Fragment>
        <Button
          style={{ outline: 'none' }}
          onClick={() => {
            this.props.closeSnackbar(key);
          }}
        >
          {'DISMISS'}
        </Button>
      </Fragment>
    );
    let newProject = this.state.projectNameInput;
    fetch('/users/addnewproject', {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({ project_name: newProject }),
      headers: new Headers({
        Authorization: localStorage.usertoken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(result => {
        if (result.errormessage) {
          if (result.code === 1) {
            this.props.enqueueSnackbar(result.errormessage, {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              variant: 'error',
              action
            });
          } else if (result.code === 2) {
            this.props.enqueueSnackbar(result.errormessage, {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              variant: 'error',
              action
            });
          }
        } else {
          this.setState({ projectNameInput: '' });
          this.props.enqueueSnackbar('Project added', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'success',
            action
          });
        }
      })
      .then(() => this.getProjects())
      .then(() => this.setState({ projectNameInput: '' }))
      .catch(err => console.log(err));
  }

  render() {
    const projects = this.state.projectsFromFetch;
    return (
      <div>
        <h1 className="admin-title">Projects Management</h1>
        <div className="proj-manag-container">
          <div className="projects-table-container">
            <div className="table-container">
              <table>
                <tbody>
                  <tr>
                    <th>All projects</th>
                  </tr>
                  {projects.map(project => {
                    return (
                      <tr key={project.project_id}>
                        <td>{project.project_name} </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="new-project-container">
              <div className="text-container">Add a new project</div>

              <div className="project-input-container">
                <input
                  type="text"
                  name="project-name"
                  id="project-input-field"
                  onChange={this.handleChange}
                />
                <div>
                  <button
                    onClick={this.handleClick}
                    id="project-add-button"
                    className="btn btn-success"
                  >
                    Add project
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(ProjectsManagement);
