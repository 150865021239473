import axios from 'axios';
import jwt_decode from 'jwt-decode';

export const auth = () => {
  if (localStorage.usertoken) {
    let token = jwt_decode(localStorage.usertoken);
    let role = token.role;
    return role;
  } else {
    return false;
  }
};

export const register = newUser => {
  return axios
    .post('users/register', {
      first_name: newUser.first_name,
      last_name: newUser.last_name,
      email: newUser.email,
      password: newUser.password
    })
    .then(() => {
      console.log('Registered');
    });
};

export const login = user => {
  return axios
    .post('users/login', {
      email: user.email,
      password: user.password
    })
    .then(response => {
      if (response.data.token !== undefined) {
        localStorage.setItem('usertoken', response.data.token);
        return response.data;
      } else {
        throw new Error('Error during login');
      }
    })
    .catch(err => {
      alert('invalid credentials', err);
    });
};
