export default function createNewTimesheet(timesheet) {
  return fetch('/users/newtimesheet', {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({ timesheet }),
    headers: new Headers({
      Authorization: localStorage.usertoken,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    })
  })
    .then(res => res.json())
    .catch(err => err);
}
