import React, { Fragment, Component } from 'react';
import { login } from '../../../actions/UserFunctions';
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const user = {
      email: this.state.email,
      password: this.state.password
    };

    login(user)
      .then(res => {
        if (res.token) {
          this.props.history.push('/profile');
        } else {
          const action = key => (
            <Fragment>
              <Button
                style={{ outline: 'none' }}
                onClick={() => {
                  this.props.closeSnackbar(key);
                }}
              >
                {'DISMISS'}
              </Button>
            </Fragment>
          );
          this.props.enqueueSnackbar('Invalid credentials', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error',
            action
          });
          this.props.history.push('/login');
        }
      })
      .catch(err => {
        console.log(err);
        this.props.history.push('/login');
      });
  }

  render() {
    return !localStorage.usertoken ? (
      <div className="container">
        <div className="row">
          <div className="col-md-6 mt-5 mx-auto">
            <form noValidate onSubmit={this.onSubmit}>
              <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
              </div>
              <button
                type="submit"
                className="btn btn-lg btn-primary btn-block"
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    ) : (
      <h1>Please log out first.</h1>
    );
  }
}

export default withSnackbar(Login);
