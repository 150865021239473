import React from 'react';
import monkey from '../../../assets/img/error-monkey.gif';
import './Error404.scss';

export default function Error404() {
  return (
    <div>
      <h1 className="error-text">Are you lost?</h1>
      <img
        className="shake"
        src={monkey}
        alt="Scratching head monkey"
        style={{ width: '50vw' }}
      />
    </div>
  );
}
