import React, { Component } from 'react';
import keytime from '../../../assets/img/keytime.png';
import './Landing.scss';

class Landing extends Component {
  render() {
    return (
      <div className="container">
        <div className="jumbotron mt-5">
          <div className="col-sm-8 mx-auto">
            <h1 className="text-center">My Keytime</h1>
          </div>
          <div className="img-container-keytime">
            <img className="project-keytime" src={keytime} alt="My Keytime" />
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
