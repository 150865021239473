export default function TimesheetGenerator(month, year, totalDays, project) {
  let timesheet = {};
  timesheet.locked = false;
  timesheet.require_lock = false;
  timesheet.year = year;
  timesheet.month = month;
  timesheet.project = project;
  timesheet.month_total = 0;
  timesheet.worked_days = {};
  for (let i = 1; i < totalDays + 1; i++) {
    timesheet.worked_days[`day${i}`] = 0;
  }
  return timesheet;
}
