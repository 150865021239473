import React, { Fragment, Component } from 'react';
import './Timesheet.scss';
import { withStyles } from '@material-ui/core/styles';
import { MagicSpinner } from 'react-spinners-kit';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import updateTimesheets from '../../../../actions/updateTimesheet';
import requestValidation from '../../../../actions/requestValidation';
import { withSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { logoKeystone } from '../../../../assets/base64/keystone';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  button: {
    outline: 'none'
  },
  table: {
    minWidth: 700
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
});

export class Timesheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      first_name: this.props.first_name,
      last_name: this.props.last_name,
      totalWorked: 0,
      previousNum: 0,
      daysWorked: {},
      timesheetsList: this.props.timesheets,
      projectsIdArray: [],
      projectsArray: []
    };
  }

  componentDidMount() {
    fetch('/users/timesheets', {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(result => {
        this.setState({ timesheetsList: result });
      })
      .then(() => this.totalWorkedMath())
      .then(() => {
        let temp = [];

        this.state.timesheetsList.forEach(sheet => {
          if (!temp.includes(sheet.project)) {
            temp.push(sheet.project);
          }
        });
        this.setState({ projectsIdArray: temp });
        let projArr = this.state.projectsIdArray;
        fetch('/users/projects/getbyids', {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({ projArr }),
          headers: new Headers({
            Authorization: localStorage.usertoken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          })
        })
          .then(res => res.json())
          .then(projectsArray => {
            this.setState({ projectsArray: projectsArray });
          })
          .catch(err => console.log('Error during projects retrieval', err));
      })
      .catch(err => console.log('Error caught : ', err));
  }
  handleSave = index => {
    const action = key => (
      <Fragment>
        <Button
          style={{ outline: 'none' }}
          onClick={() => {
            this.props.closeSnackbar(key);
          }}
        >
          {'DISMISS'}
        </Button>
      </Fragment>
    );
    this.totalWorkedMath();
    updateTimesheets(this.state.timesheetsList[index], index)
      .then(response => {
        if (response.ok) {
          this.props.enqueueSnackbar('Successfully saved timesheet.', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'success',
            action
          });
        }
      })
      .catch(error => {
        this.props.enqueueSnackbar('Failed saving timesheet.', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error',
          action
        });
        console.log(error);
      });
    this.setState({ timesheetslist: this.props.timesheets });
  };

  totalWorkedMath = () => {
    let tryhard;
    let tot = 0;
    let copy = this.state.timesheetsList;
    if (this.state.timesheetsList[this.props.sheetIndex] !== undefined) {
      tryhard = this.state.timesheetsList[this.props.sheetIndex].worked_days;

      let entries = Object.entries(tryhard);
      for (let [day, hours] of entries) {
        if (day) {
          tot += hours;
        }
      }
    }
    if (copy[this.props.sheetIndex] !== undefined) {
      copy[this.props.sheetIndex].month_total = tot;
      this.setState({ timesheetsList: copy, totalWorked: tot });
    }
  };

  handleChange = evt => {
    let dayToInsert = {};
    const index = this.props.sheetIndex;
    let copy = [...this.state.timesheetsList];
    dayToInsert[evt.target.id] = evt.target.value;
    if (parseFloat(dayToInsert[evt.target.id]) >= 0) {
      copy[index].worked_days[evt.target.id] = parseFloat(
        dayToInsert[evt.target.id]
      );
    }
    this.setState({ timesheetsList: copy });
  };

  handleValidate = index => {
    const action = key => (
      <Fragment>
        <Button
          style={{ outline: 'none' }}
          onClick={() => {
            this.props.closeSnackbar(key);
          }}
        >
          {'DISMISS'}
        </Button>
      </Fragment>
    );
    let requireLock = !this.state.timesheetsList[index].require_lock;
    requestValidation(this.state.timesheetsList[index], index, requireLock)
      .then(response => {
        if (response.ok && requireLock) {
          this.props.enqueueSnackbar('Submitted for validation', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'success',
            action
          });
        } else if (response.ok && !requireLock) {
          this.props.enqueueSnackbar('Cancelled validation request', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'success',
            action
          });
        }
      })
      .then(
        fetch('/users/timesheets', {
          method: 'get',
          headers: new Headers({
            Authorization: localStorage.usertoken,
            'Content-Type': 'application/json'
          })
        })
          .then(res => res.json())
          .then(result => {
            this.setState({ timesheetsList: result });
          })
          .then(() => this.totalWorkedMath())
          .then(() => {
            let temp = [];
            this.state.timesheetsList.forEach(sheet => {
              if (!temp.includes(sheet.project)) {
                temp.push(sheet.project);
              }
            });
            this.setState({ projectsIdArray: temp });
            let projArr = this.state.projectsIdArray;
            fetch('/users/projects/getbyids', {
              method: 'POST',
              mode: 'cors',
              body: JSON.stringify({ projArr }),
              headers: new Headers({
                Authorization: localStorage.usertoken,
                Accept: 'application/json',
                'Content-Type': 'application/json'
              })
            })
              .then(res => res.json())
              .then(projectsArray => {
                this.setState({ projectsArray: projectsArray });
              })
              .catch(err =>
                console.log('Error during projects retrieval', err)
              );
          })
          .catch(err => console.log('Error caught : ', err))
      )
      .catch(error => {
        this.props.enqueueSnackbar('Failed submitting lock request', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error',
          action
        });
        console.log(error);
      });
  };

  handleExport = (
    index,
    now,
    currentProject,
    firstDay,
    numOfDays,
    arrayOfWeekends
  ) => {
    this.setState({ loading: true });
    let dd = {
      content: [
        {
          image: `data:image/png;base64,${logoKeystone}`,
          width: 130,
          alignment: 'left'
        },
        {
          text: `Timesheet for ${('0' + (now.getMonth() + 1)).slice(
            -2
          )}/${now.getFullYear()}`,
          style: 'title'
        },
        {
          text: ['Customer : ', { text: `${currentProject}`, bold: true }],
          style: 'p'
        },
        {
          text: [
            'Employee : ',
            {
              text: `${this.state.first_name} ${this.state.last_name}`,
              bold: true
            }
          ],
          style: 'p2'
        },
        // Table here Index : 3 / dd.content[3].table.body[0-5]
        {
          table: {
            widths: [80, '*', '*', '*', '*', '*', '*', '*'],
            heights: ['*', 30, 30, 30, 30, 30, 30],
            headerRows: 1,
            body: [
              [
                {},
                { text: 'Mon', style: 'tableheader' },
                { text: 'Tue', style: 'tableheader' },
                { text: 'Wed', style: 'tableheader' },
                { text: 'Thu', style: 'tableheader' },
                { text: 'Fri', style: 'tableheader' },
                { text: 'Sat', style: 'tableheader' },
                { text: 'Sun', style: 'tableheader' }
              ],
              [
                { text: 'Week 1', style: 'tableheaderWeek' },
                { text: '' },
                {},
                {},
                {},
                {},
                {},
                {}
              ],
              [
                { text: 'Week 2', style: 'tableheaderWeek' },
                {},
                {},
                {},
                {},
                {},
                {},
                {}
              ],
              [
                { text: 'Week 3', style: 'tableheaderWeek' },
                {},
                {},
                {},
                {},
                {},
                {},
                {}
              ],
              [
                { text: 'Week 4', style: 'tableheaderWeek' },
                {},
                {},
                {},
                {},
                {},
                {},
                {}
              ],
              [
                { text: 'Week 5', style: 'tableheaderWeek' },
                {},
                {},
                {},
                {},
                {},
                {},
                {}
              ]
            ]
          }
        },
        {
          margin: [0, 40, 0, 0],
          text: 'Remarks: ',
          alignment: 'left'
        },
        {
          margin: [0, 120, 10, 0],
          alignment: 'right',
          columns: [
            {
              text: ''
            },
            {
              text: '',
              width: 10
            },
            {
              text: 'Date : ..... / ..... / .........'
            }
          ]
        },
        {
          margin: [0, 20, 0, 0],

          alignment: 'center',
          columns: [
            {
              text: 'Signature Customer'
            },
            {
              text: '',
              width: 10
            },
            {
              text: 'Signature Employee'
            }
          ]
        },
        {
          margin: [0, 10, 0, 0],
          alignment: 'justify',
          columns: [
            {
              table: {
                widths: ['*'],
                heights: [70],
                body: [[{}]]
              }
            },
            {
              text: '',
              width: 10
            },
            {
              table: {
                widths: ['*'],
                heights: [70],
                body: [[{}]]
              }
            }
          ]
        }
      ],

      pageSize: 'A4',
      pageMargins: [40, 20, 40, 20],
      styles: {
        tableheaderTotal: {
          bold: true,
          fontSize: 16,
          alignment: 'right',
          margin: [0, 6, 0, 0]
        },
        tableheaderWeek: {
          bold: true,
          fontSize: 16,
          margin: [0, 6, 0, 0],
          alignment: 'left'
        },
        tableheader: {
          bold: true,
          fontSize: 16,
          alignment: 'center'
        },
        title: {
          fontSize: 20,
          bold: true,
          alignment: 'center',
          margin: [0, 25, 0, 0],
          decoration: 'underline'
        },
        topHeader: {
          fontSize: 20,
          bold: true,
          margin: [0, 6, 0, 30],
          alignment: 'left'
        },
        table: {
          fontSize: 8,
          alignment: 'left',
          color: 'black',
          margin: [0, 5, 0, 15]
        },
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 25, 0, 15],
          alignment: 'left'
        },
        p: {
          fontSize: 14,
          alignment: 'left',
          margin: [0, 35, 0, 5]
        },
        p2: {
          fontSize: 14,
          alignment: 'left',
          margin: [0, 0, 0, 45]
        },
        footer: {
          fontSize: 8,
          margin: [0, 25, 0, 17],
          alignment: 'center'
        },
        daycell: {
          color: 'grey',
          alignment: 'center'
        },
        hourscell: {
          color: 'black',
          alignment: 'center',
          bold: true
        }
      }
    };

    let totalDays = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      0
    ).getDate();
    let weekCpt = 1;
    let dayOfMonth = firstDay;
    if (dayOfMonth === 0) {
      dayOfMonth = 7;
    }
    for (let i = 1; i <= totalDays; i++) {
      dd.content[4].table.body[weekCpt][dayOfMonth].text = [
        { text: i, style: 'daycell' },
        {
          text:
            '\n' +
            this.state.timesheetsList[index].worked_days[`day${i}`] +
            ' h',
          style: 'hourscell'
        }
      ];
      if (dayOfMonth === 0) {
        dayOfMonth = 7;
      } else if (dayOfMonth === 7 && i !== totalDays) {
        dayOfMonth = 1;
        weekCpt += 1;
      } else if (i !== totalDays) {
        dayOfMonth += 1;
      }
      if (weekCpt > 5 && dd.content[4].table.body[6] === undefined) {
        dd.content[4].table.body.push([
          { text: 'Week 6', style: 'tableheaderWeek' },
          {},
          {},
          {},
          {},
          {},
          {},
          {}
        ]);
      }
    }

    dd.content[4].table.body.push([
      {
        text: `Total : ${
          this.state.timesheetsList[index].month_total
        }/${(numOfDays - arrayOfWeekends.length) * 8} hours`,
        style: 'tableheaderTotal',
        colSpan: 8
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ]);

    setTimeout(() => {
      pdfMake
        .createPdf(dd)
        .download(
          `${this.state.first_name.substr(
            0,
            1
          )}${this.state.last_name.toUpperCase()}-${now.getMonth() +
            1}-${now.getFullYear()}`
        );
      this.setState({ loading: false });
    }, 2000);
  };

  getWeekends(now) {
    let arrayOfWeekends = [];
    function getDaysInMonth(now) {
      return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    }
    let days = getDaysInMonth(now);

    for (let i = 1; i <= days; i++) {
      let newDate = new Date(now.getFullYear(), now.getMonth(), i);
      if (newDate.getDay() === 0 || newDate.getDay() === 6) {
        arrayOfWeekends.push(i);
      }
    }

    return arrayOfWeekends;
  }

  render() {
    const { classes, selectedDate, sheetIndex, past_projects } = this.props;
    let { loading } = this.state;
    let firstDay = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      1
    ).getDay();
    let arrayOfWeekends = this.getWeekends(selectedDate);
    let els = [];
    let now = selectedDate;
    let projectId = this.state.timesheetsList[sheetIndex].project;
    let currentProject = past_projects.filter(project => {
      return project.project_id === projectId;
    });

    function getDaysInMonth(now) {
      return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    }
    const days = getDaysInMonth(now);

    for (let i = 1; i <= days; i++) {
      els.push(i);
    }

    return (
      <div className="timesheet-container">
        {loading ? (
          <div className="spinner">
            <MagicSpinner size={50} color={'rgb(50, 141, 238)'} />
          </div>
        ) : (
          false
        )}
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Project</CustomTableCell>
                <CustomTableCell align="left">Day</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={
                this.state.timesheetsList[sheetIndex].locked
                  ? { backgroundColor: 'rgb(103, 103, 103)' }
                  : {}
              }
            >
              <TableRow>
                <CustomTableCell className="project-cell">
                  {currentProject[0]
                    ? currentProject[0].project_name
                    : 'Loading'}
                </CustomTableCell>
                <CustomTableCell align="left">
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow className="fullrow limit-height">
                        {els.map(day => {
                          return arrayOfWeekends.includes(day) ? (
                            <th className="header-days weekend" key={day}>
                              {day}
                            </th>
                          ) : (
                            <th className="header-days" key={day}>
                              {day}
                            </th>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className="fullrow limit-height">
                        {els.map(day => {
                          let dayKey = `day${day}`;
                          return (
                            <td className="daycell" key={day}>
                              <input
                                disabled={
                                  this.state.timesheetsList[sheetIndex].locked
                                }
                                style={{ borderRadius: '3px' }}
                                id={`day${day}`}
                                type="text"
                                onChange={evt => this.handleChange(evt)}
                                placeholder={
                                  this.state.timesheetsList[
                                    this.props.sheetIndex
                                  ] !== undefined
                                    ? this.state.timesheetsList[sheetIndex]
                                        .worked_days[dayKey]
                                    : 0
                                }
                                key={day}
                              />
                            </td>
                          );
                        })}
                      </TableRow>
                    </TableBody>
                  </Table>
                </CustomTableCell>
              </TableRow>
            </TableBody>
          </Table>

          <div
            className="totaldays"
            style={
              this.state.timesheetsList[sheetIndex].locked
                ? { backgroundColor: '#5cb14a' }
                : {}
            }
          >
            <div className="legends">
              <div className="timesheet-locked-status">
                {this.state.timesheetsList[sheetIndex].locked
                  ? 'Timesheet has been validated. If you need help, please contact hr@keystone-solutions.be'
                  : this.state.timesheetsList[sheetIndex].require_lock
                  ? 'Timesheet sent for validation'
                  : ''}
              </div>
            </div>
            <div className="total-text">
              Total : {this.state.totalWorked}/
              {(days - arrayOfWeekends.length) * 8} hours
            </div>

            <div className="save-timesheet">
              <button
                title="Save this timesheet to the database. You can still come back and change it later as long as it isn't validated."
                className="savebutton"
                disabled={
                  this.state.timesheetsList[sheetIndex].locked ||
                  this.state.timesheetsList[sheetIndex].require_lock
                }
                onClick={() => this.handleSave(sheetIndex)}
              >
                <FontAwesomeIcon icon={faSave} />
              </button>
              <button
                title="Send this timesheet for validation to your administrator"
                className="validatebutton"
                onClick={() => this.handleValidate(sheetIndex)}
                disabled={this.state.timesheetsList[sheetIndex].locked}
              >
                <FontAwesomeIcon icon={faCheckCircle} />
              </button>
              <button
                title="Export this timesheet as a PDF"
                className="exportbutton"
                onClick={() =>
                  this.handleExport(
                    sheetIndex,
                    now,
                    currentProject[0].project_name,
                    firstDay,
                    days,
                    arrayOfWeekends
                  )
                }
              >
                <FontAwesomeIcon icon={faFileDownload} />
              </button>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(Timesheet));
