import React, { Fragment, Component } from 'react';
import { MagicSpinner } from 'react-spinners-kit';
import Timesheet from './Timesheet/Timesheet';
import TimesheetGenerator from '../../../helpers/TimesheetGenerator';
import jwt_decode from 'jwt-decode';
import DatePicker from 'react-datepicker';
import createNewTimesheet from '../../../actions/createNewTimesheet';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import { logoKeystone } from '../../../assets/base64/keystone';
import pdfMake from 'pdfmake';
import './Timesheets.scss';
import 'react-datepicker/dist/react-datepicker.css';
const _ = require('lodash');

export class Timesheets extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      projects: [],
      first_name: '',
      last_name: '',
      timesheets: [],
      selectedDate: new Date(),
      pastProjects: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.exportAll = this.exportAll.bind(this);
    this.handleRemaining = this.handleRemaining.bind(this);
  }

  componentDidMount() {
    const action = key => (
      <Fragment>
        <Button
          style={{ outline: 'none' }}
          onClick={() => {
            this.props.closeSnackbar(key);
          }}
        >
          {'DISMISS'}
        </Button>
      </Fragment>
    );

    if (localStorage.usertoken) {
      const token = localStorage.usertoken;
      const decoded = jwt_decode(token);
      this.setState({
        first_name: decoded.first_name,
        last_name: decoded.last_name
      });
    }
    fetch('/users/timesheets', {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(result => {
        this.setState({ timesheets: result });
      })
      .catch(err => {
        this.props.enqueueSnackbar('Server is down', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error',
          action
        });
        console.log(err);
      });

    fetch('/users/projects', {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(result => {
        return result;
      })
      .then(projects => this.setState({ projects: projects }))
      .catch(err => console.log('Error caught : ', err));

    fetch('/users/projects/pastprojects', {
      method: 'get',
      headers: new Headers({
        Authorization: localStorage.usertoken,
        'Content-Type': 'application/json'
      })
    })
      .then(res => res.json())
      .then(result => this.setState({ pastProjects: result }));
  }

  handleChange(date) {
    this.setState({
      selectedDate: date
    });
  }

  addNewTimesheet() {
    let length = this.state.projects.length;
    let month = this.state.selectedDate.getMonth() + 1;
    let year = this.state.selectedDate.getFullYear();
    let daysNumber = new Date(year, month, 0).getDate();
    let sheets = [...this.state.timesheets];
    for (let i = 0; i < length; i++) {
      sheets.push(
        TimesheetGenerator(
          month,
          year,
          daysNumber,
          this.state.projects[i].project_id
        )
      );
    }
    for (let i = sheets.length - length; i < sheets.length; i++) {
      createNewTimesheet(sheets[i]);
    }
    this.setState({ timesheets: sheets });
  }

  handleRemaining() {
    let currentSheets = [];
    let now = this.state.selectedDate;
    let month = now.getMonth() + 1;
    let year = now.getFullYear();
    let daysNumber = new Date(year, month, 0).getDate();
    let timesheets = [...this.state.timesheets];
    let projects = [...this.state.projects];
    let arrayOfCurrentProjects = projects.map(project => {
      return project.project_id;
    });

    timesheets.forEach(timesheet => {
      if (timesheet.month === month && timesheet.year === year) {
        currentSheets.push(timesheet);
      }
    });
    let arrayOfRenderedProjects = currentSheets.map(sheet => {
      return sheet.project;
    });
    if (currentSheets.length < projects.length) {
      let diff = _.difference(arrayOfCurrentProjects, arrayOfRenderedProjects);
      let length = diff.length;
      for (let i = 0; i < length; i++) {
        timesheets.push(TimesheetGenerator(month, year, daysNumber, diff[i]));
      }
      for (let i = timesheets.length - length; i < timesheets.length; i++) {
        createNewTimesheet(timesheets[i]);
      }
      this.setState({ timesheets: timesheets });
    }
  }

  getWeekends(now) {
    let arrayOfWeekends = [];
    function getDaysInMonth(now) {
      return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    }
    let days = getDaysInMonth(now);

    for (let i = 1; i <= days; i++) {
      let newDate = new Date(now.getFullYear(), now.getMonth(), i);
      if (newDate.getDay() === 0 || newDate.getDay() === 6) {
        arrayOfWeekends.push(i);
      }
    }

    return arrayOfWeekends;
  }

  getDaysInMonth(now) {
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
  }

  exportAll() {
    this.setState({ loading: true });
    let projects = [...this.state.pastProjects];
    let sheetsCopy = [...this.state.timesheets];
    let now = this.state.selectedDate;
    let projectsIds = projects.map(project => project.project_id);
    let firstDay = new Date(now.getFullYear(), now.getMonth(), 1).getDay();
    let sheetsFiltered = sheetsCopy.filter(sheet => {
      return (
        sheet.month === now.getMonth() + 1 &&
        sheet.year === now.getFullYear() &&
        projectsIds.includes(parseInt(sheet.project))
      );
    });
    let projIdsOfFilteredSheets = sheetsFiltered.map(sheet => {
      return sheet.project;
    });

    let customerNames = [];
    projects.forEach(project => {
      if (projIdsOfFilteredSheets.includes(project.project_id)) {
        customerNames.push(project.project_name);
      }
    });

    let weekStack = [
      { stack: [{ text: 'Week 1', style: 'tableheaderWeek' }] },
      { stack: [{ text: 'Week 2', style: 'tableheaderWeek' }] },
      { stack: [{ text: 'Week 3', style: 'tableheaderWeek' }] },
      { stack: [{ text: 'Week 4', style: 'tableheaderWeek' }] },
      { stack: [{ text: 'Week 5', style: 'tableheaderWeek' }] },
      { stack: [{ text: 'Week 6', style: 'tableheaderWeek' }] }
    ];
    let customersText = '';
    customerNames.forEach((customer, index) => {
      customersText += customerNames[index];
      if (index !== customerNames.length - 1) {
        customersText += ', ';
      }
      let stacki = 0;
      while (stacki < 6) {
        weekStack[stacki].stack.push({
          text: customer,
          style: 'tableheaderCustomer'
        });
        stacki++;
      }
    });

    let arrayOfWeekends = this.getWeekends(now);
    let numOfDays = this.getDaysInMonth(now);
    let dd = {
      content: [
        {
          image: `data:image/png;base64,${logoKeystone}`,
          width: 130,
          alignment: 'left'
        },
        {
          text: `Timesheets for ${('0' + (now.getMonth() + 1)).slice(
            -2
          )}/${now.getFullYear()}`,
          style: 'title'
        },
        {
          text: [
            'Customers : ',
            {
              text: `${customersText}`,
              bold: true
            }
          ],
          style: 'p'
        },
        {
          text: [
            'Employee : ',
            {
              text: `${this.state.first_name} ${this.state.last_name}`,
              bold: true
            }
          ],
          style: 'p2'
        },
        // Table here Index : 4 / dd.content[4].table.body[0-5]
        {
          table: {
            widths: [80, '*', '*', '*', '*', '*', '*', '*'],
            heights: ['*', 30, 30, 30, 30, 30, 30],
            headerRows: 1,
            body: [
              //Headers MON TUE WED...
              [
                {},
                { text: 'Mon', style: 'tableheader' },
                { text: 'Tue', style: 'tableheader' },
                { text: 'Wed', style: 'tableheader' },
                { text: 'Thu', style: 'tableheader' },
                { text: 'Fri', style: 'tableheader' },
                { text: 'Sat', style: 'tableheader' },
                { text: 'Sun', style: 'tableheader' }
              ],
              //Week1
              [weekStack[0], {}, {}, {}, {}, {}, {}, {}],
              //Week2
              [weekStack[1], {}, {}, {}, {}, {}, {}, {}],
              //Week3
              [weekStack[2], {}, {}, {}, {}, {}, {}, {}],
              //Week4
              [weekStack[3], {}, {}, {}, {}, {}, {}, {}],
              //Week5
              [weekStack[4], {}, {}, {}, {}, {}, {}, {}]
            ]
          }
        },
        //REST OF CONTENT
        {
          margin: [0, 30, 0, 0],
          text: 'Remarks: ',
          alignment: 'left'
        },
        {
          margin: [0, 90, 10, 0],
          alignment: 'right',
          columns: [
            {
              text: ''
            },
            {
              text: '',
              width: 10
            },
            {
              text: 'Date : ..... / ..... / .........'
            }
          ]
        },
        {
          margin: [0, 20, 0, 0],

          alignment: 'center',
          columns: [
            {
              text: 'Signature Customer',
              id: 'signature'
            },
            {
              text: '',
              width: 10
            },
            {
              text: 'Signature Employee'
            }
          ]
        },
        {
          margin: [0, 10, 0, 0],
          alignment: 'justify',
          columns: [
            {
              table: {
                widths: ['*'],
                heights: [70],
                body: [[{}]]
              }
            },
            {
              text: '',
              width: 10
            },
            {
              table: {
                widths: ['*'],
                heights: [70],
                body: [[{}]]
              }
            }
          ]
        }
      ],

      pageSize: 'A4',
      pageMargins: [40, 20, 40, 20],
      styles: {
        tableheaderTotal: {
          bold: true,
          fontSize: 16,
          alignment: 'right',
          margin: [0, 6, 0, 0]
        },
        tableheaderWeek: {
          bold: true,
          fontSize: 10,
          margin: [0, 3, 0, 0],
          alignment: 'left'
        },
        tableheaderCustomer: {
          bold: false,
          fontSize: 8,
          margin: [0, 3, 0, 0],
          alignment: 'left'
        },
        tableheader: {
          bold: true,
          fontSize: 16,
          alignment: 'center'
        },
        title: {
          fontSize: 20,
          bold: true,
          alignment: 'center',
          margin: [0, 25, 0, 0],
          decoration: 'underline'
        },
        topHeader: {
          fontSize: 20,
          bold: true,
          margin: [0, 6, 0, 30],
          alignment: 'left'
        },
        table: {
          fontSize: 8,
          alignment: 'left',
          color: 'black',
          margin: [0, 5, 0, 15]
        },
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 25, 0, 15],
          alignment: 'left'
        },
        p: {
          fontSize: 14,
          alignment: 'left',
          margin: [0, 35, 0, 5]
        },
        p2: {
          fontSize: 14,
          alignment: 'left',
          margin: [0, 0, 0, 45]
        },
        footer: {
          fontSize: 8,
          margin: [0, 25, 0, 17],
          alignment: 'center'
        },
        daycell: {
          color: 'grey',
          bold: true,
          fontSize: 10,
          margin: [0, 3, 0, 0],
          alignment: 'center'
        },
        hourscell: {
          color: 'black',
          alignment: 'center',
          margin: [0, 3, 0, 0],
          bold: true,
          fontSize: 8
        },
        smallTotal: {
          bold: false,
          fontSize: 10,
          alignment: 'right',
          margin: [0, 3, 0, 0]
        }
      }
    };

    if (sheetsFiltered.length > 2) {
      dd.content[5].margin = [0, 10, 0, 0];
      dd.content[6].margin = [0, 30, 10, 0];
    }

    let totalDays = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      0
    ).getDate();
    let weekCpt = 1;
    let dayOfMonth = firstDay;
    if (dayOfMonth === 0) {
      dayOfMonth = 7;
    }
    for (let i = 1; i <= totalDays; i++) {
      dd.content[4].table.body[weekCpt][dayOfMonth].stack = [
        { text: i, style: 'daycell' }
      ];
      // eslint-disable-next-line
      sheetsFiltered.forEach(sheet => {
        dd.content[4].table.body[weekCpt][dayOfMonth].stack.push({
          text: sheet.worked_days[`day${i}`] + ' h',
          style: 'hourscell'
        });
      });

      if (dayOfMonth === 0) {
        dayOfMonth = 7;
      } else if (dayOfMonth === 7 && i !== totalDays) {
        dayOfMonth = 1;
        weekCpt += 1;
      } else if (i !== totalDays) {
        dayOfMonth += 1;
      }
      if (weekCpt > 5 && dd.content[4].table.body[6] === undefined) {
        dd.content[4].table.body.push([
          weekStack[5],
          {},
          {},
          {},
          {},
          {},
          {},
          {}
        ]);
      }
    }
    let grandTotal = 0;
    sheetsFiltered.forEach(sheet => {
      grandTotal += sheet.month_total;
    });
    let finalPushTotal = [
      {
        stack: [
          {
            text: `Total : ${grandTotal}/${(numOfDays -
              arrayOfWeekends.length) *
              8} hours`,
            style: 'tableheaderTotal'
          }
        ],
        colSpan: 8
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ];
    sheetsFiltered.forEach((sheet, index) => {
      let smallTotal = {};
      smallTotal.text = `${customerNames[index]} : ${sheet.month_total} h`;
      smallTotal.style = 'smallTotal';
      finalPushTotal[0].stack.push(smallTotal);
    });

    dd.content[4].table.body.push(finalPushTotal);

    setTimeout(() => {
      pdfMake
        .createPdf(dd)
        .download(
          `${this.state.first_name.substr(
            0,
            1
          )}${this.state.last_name.toUpperCase()}timesheet`
        );
      this.setState({ loading: false });
    }, 2000);
  }

  render() {
    let { loading } = this.state;
    let renderAddTimesheets = false;
    let noExport = false;
    let selectedMonth = this.state.selectedDate.getMonth() + 1;
    let selectedYear = this.state.selectedDate.getFullYear();
    let timesheetsToRender = [];
    let timesheetsArray = [];
    if (this.state.timesheets[0] === undefined) {
      return null;
    } else {
      timesheetsArray = this.state.timesheets;
    }

    if (timesheetsArray.length >= 1) {
      timesheetsArray.forEach((timesheet, index) => {
        let d = new Date(timesheet.year, timesheet.month - 1);
        if (
          timesheet.month === selectedMonth &&
          timesheet.year === selectedYear
        ) {
          timesheetsToRender.push(
            <Timesheet
              timesheets={this.state.timesheets}
              key={index}
              selectedDate={d}
              sheetIndex={index}
              first_name={this.state.first_name}
              last_name={this.state.last_name}
              past_projects={this.state.pastProjects}
            />
          );
        } else if (
          !timesheetsArray.find(
            timesheet =>
              timesheet.month === selectedMonth &&
              timesheet.year === selectedYear
          )
        ) {
          noExport = true;
          renderAddTimesheets = true;
          timesheetsToRender = [
            <div key={`${timesheet.month}/${timesheet.year}`}>
              <h2 className="mt-5">No timesheets for this period</h2>
              <div className="sheet-add" onClick={() => this.addNewTimesheet()}>
                Add timesheets
              </div>
            </div>
          ];
        }
      });
    }

    let exportAllButton = (
      <div onClick={this.exportAll} className="mass-export">
        Export All
      </div>
    );

    let remainingButton = (
      <div
        className="add-remaining-sheets"
        onClick={() => this.handleRemaining()}
      />
    );
    if (
      timesheetsToRender.length === this.state.projects.length ||
      renderAddTimesheets === true
    ) {
      remainingButton = null;
    }

    if (noExport || timesheetsToRender.length < 2) {
      exportAllButton = null;
    }

    return (
      <div className="timesheets">
        {loading ? (
          <div className="spinner">
            <MagicSpinner size={50} color={'#608eb6'} />
          </div>
        ) : (
          false
        )}
        <h3>
          Timesheet(s) for :
          <DatePicker
            id="custom-datepicker-keystone"
            selected={this.state.selectedDate}
            onChange={this.handleChange}
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
        </h3>
        {timesheetsToRender.map(timesheetRendered => timesheetRendered)}
        <div className="flex-container-export">
          {remainingButton}
          {exportAllButton}
        </div>
      </div>
    );
  }
}

export default withSnackbar(Timesheets);
