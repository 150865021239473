export default function updateTimesheets(timesheet, index) {
  return fetch('/users/timesheets', {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({ timesheet, index }),
    headers: new Headers({
      Authorization: localStorage.usertoken,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    })
  })
    .then(res => {
      if (res.ok) {
        return res;
      } else {
        throw new Error(res.statusText);
      }
    })
    .catch(err => {
      console.log('Server cannot be reached', err);
      return Promise.reject();
    });
}
