import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ProjectsCell from './ProjectsCell/ProjectsCell';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const styles = () => ({
  root: {
    width: '90%',
    margin: '50px auto',
    overflowX: 'auto'
  },
  table: {
    minWidth: '500px'
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#cccdce'
    }
  }
});

class Userstable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchedProjects: false
    };
  }

  toDate = dateStr => {
    let parts = dateStr.split('-');
    let reversed = [parts[2], parts[1], parts[0]];
    return reversed.join('/');
  };

  render() {
    const { classes, users } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.row}>
              <CustomTableCell key="1">Name</CustomTableCell>
              <CustomTableCell key="2" align="right">
                Email
              </CustomTableCell>
              <CustomTableCell key="3" align="right">
                Date Joined
              </CustomTableCell>
              <CustomTableCell key="4" align="right">
                Role
              </CustomTableCell>
              <CustomTableCell key="5" align="right">
                Projects
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user =>
              user.role !== 'admin' ? (
                <React.Fragment key={user.id}>
                  <TableRow className={classes.row} key={user.id}>
                    <CustomTableCell component="th" scope="row">
                      {user.first_name + ' ' + user.last_name}
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      {user.email}
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      {this.toDate(user.created.slice(0, 10))}
                    </CustomTableCell>
                    <CustomTableCell align="right">{user.role}</CustomTableCell>
                    <CustomTableCell align="right">
                      <ProjectsCell user={user} />
                    </CustomTableCell>
                  </TableRow>
                </React.Fragment>
              ) : null
            )}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

Userstable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Userstable);
